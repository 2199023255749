import React from "react";
import Base from "./componentes/Base";
import Negocio from "./componentes/Negocio";
import Usuario from "./componentes/Usuario";

function App() {
  return (
    <div>
      <Negocio />
    </div>
  );
}

export default App;
