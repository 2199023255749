export default function Logo() {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "baseline",
            margin: "12px"
        }}>
            <svg width="53" height="48" viewBox="0 0 53 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M50.6487 9.02829L48.3216 11.3594L23.0036 39.1349L18.2986 44.3028C17.1542 45.5485 15.1323 45.5115 14.0514 44.2164L9.58803 38.8513L0 27.3562C2.06003 26.4559 4.17092 26.1598 6.01477 26.1598C8.55801 26.1598 10.618 26.7149 11.3556 27.0479C13.1994 27.8619 14.7254 29.2063 15.997 30.7357L16.4548 31.2907L32.6044 13.5794C34.4101 11.606 36.5846 9.95332 39.0642 8.89262C42.4086 7.4619 45.5241 7.21523 50.636 9.04062L50.6487 9.02829Z" fill="#FF7F00" />
                <path d="M27.8231 0V13.6411L17.4975 24.9758L17.4721 25.0005L16.7473 25.7282V0H27.8231Z" fill="#1A1A1A" />
                <path d="M52.6324 47.8672H38.7209C38.1232 47.8672 37.5637 47.6082 37.1822 47.1642L29.0947 37.5439L36.8262 29.0583L52.6324 47.8672Z" fill="#1A1A1A" />
            </svg>
            <svg width="244" height="38" viewBox="0 0 244 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M29.8716 1.3318V26.912H22.6106L22.0765 23.8532C19.8385 26.2336 16.9138 27.43 13.3278 27.43C10.8736 27.43 8.68637 26.8626 6.79165 25.7403C4.88422 24.6179 3.39642 23.0515 2.32825 21.0288C1.26009 19.006 0.726013 16.6996 0.726013 14.1219C0.726013 11.5441 1.26009 9.22539 2.32825 7.20266C3.39642 5.17992 4.88422 3.61354 6.79165 2.49117C8.69908 1.3688 10.8736 0.813782 13.3278 0.813782C15.1081 0.813782 16.7484 1.10979 18.2362 1.71415C19.724 2.3185 21.0084 3.19419 22.0765 4.3289L22.6106 1.3318H29.8716ZM19.8385 18.8087C21.0084 17.6123 21.606 16.0583 21.606 14.1219C21.606 12.1855 21.0211 10.6314 19.8385 9.43507C18.6686 8.25103 17.1554 7.64667 15.2988 7.64667C13.4422 7.64667 11.9417 8.25102 10.7845 9.45973C9.62737 10.6684 9.05513 12.2225 9.05513 14.1219C9.05513 16.0213 9.62737 17.5753 10.7845 18.784C11.9417 19.9927 13.4422 20.5971 15.2988 20.5971C17.1554 20.5971 18.6686 20.0051 19.8385 18.8087Z" fill="#FF7F00" />
                <path d="M76.2095 3.61356C78.1296 5.51295 79.0961 8.11537 79.0961 11.4331V26.912H70.7161V12.4692C70.7161 10.9521 70.3473 9.76809 69.6225 8.9294C68.8977 8.09071 67.8676 7.65902 66.5579 7.65902C65.0955 7.65902 63.9383 8.14004 63.0864 9.11441C62.2344 10.0764 61.802 11.3591 61.802 12.9502V26.9367H53.4729V12.4938C53.4729 10.9768 53.1168 9.79276 52.4047 8.95407C51.6926 8.11537 50.6753 7.68369 49.3656 7.68369C47.9032 7.68369 46.746 8.16471 45.8686 9.13907C44.9912 10.1011 44.5588 11.3838 44.5588 12.9749V26.9613H36.1788V1.33182H43.4398L43.923 4.18091C44.8513 3.07087 45.9576 2.23218 47.2547 1.64016C48.5517 1.04814 49.9505 0.764465 51.451 0.764465C53.155 0.764465 54.6936 1.12214 56.0416 1.82517C57.3895 2.52819 58.4576 3.50256 59.246 4.74826C61.3824 2.09651 64.4088 0.764465 68.3254 0.764465C71.6698 0.764465 74.3021 1.71416 76.2222 3.61356H76.2095Z" fill="#FF7F00" />
                <path d="M108.191 2.5035C110.111 3.62587 111.611 5.19226 112.68 7.21499C113.748 9.23772 114.282 11.5441 114.282 14.1342C114.282 16.7243 113.748 19.0307 112.68 21.0411C111.611 23.0638 110.124 24.6302 108.191 25.7526C106.271 26.875 104.083 27.4423 101.629 27.4423C98.3484 27.4423 95.6271 26.4433 93.4654 24.4452V38.0123H85.0854V1.3318H92.3464L92.8804 4.3289C95.0931 1.98549 98.0051 0.813782 101.642 0.813782C104.096 0.813782 106.283 1.3688 108.203 2.49117L108.191 2.5035ZM104.185 18.8087C105.355 17.6123 105.953 16.0583 105.953 14.1219C105.953 12.1855 105.368 10.6314 104.185 9.43506C103.015 8.25103 101.502 7.64667 99.6455 7.64667C97.7889 7.64667 96.2884 8.25102 95.1312 9.45973C93.974 10.6684 93.4018 12.2225 93.4018 14.1219C93.4018 16.0213 93.974 17.5753 95.1312 18.784C96.2884 19.9927 97.7889 20.5971 99.6455 20.5971C101.502 20.5971 103.015 20.0051 104.185 18.8087Z" fill="#1A1A1A" />
                <path d="M146.683 1.3318V26.912H139.422L138.888 23.8532C136.65 26.2336 133.725 27.43 130.139 27.43C127.685 27.43 125.498 26.8626 123.603 25.7403C121.695 24.6179 120.208 23.0515 119.139 21.0288C118.071 19.006 117.537 16.6996 117.537 14.1219C117.537 11.5441 118.071 9.22539 119.139 7.20266C120.208 5.17992 121.695 3.61354 123.603 2.49117C125.51 1.3688 127.685 0.813782 130.139 0.813782C131.919 0.813782 133.56 1.10979 135.047 1.71415C136.535 2.3185 137.82 3.19419 138.888 4.3289L139.422 1.3318H146.683ZM136.65 18.8087C137.82 17.6123 138.417 16.0583 138.417 14.1219C138.417 12.1855 137.832 10.6314 136.65 9.43507C135.48 8.25103 133.967 7.64667 132.11 7.64667C130.253 7.64667 128.753 8.25102 127.596 9.45973C126.439 10.6684 125.866 12.2225 125.866 14.1219C125.866 16.0213 126.439 17.5753 127.596 18.784C128.753 19.9927 130.253 20.5971 132.11 20.5971C133.967 20.5971 135.48 20.0051 136.65 18.8087Z" fill="#1A1A1A" />
                <path d="M177.087 3.87254C179.033 5.94461 179.999 8.80604 179.999 12.4692V26.912H171.619V12.9255C171.619 11.2728 171.187 9.97774 170.335 9.04038C169.483 8.10301 168.3 7.64666 166.813 7.64666C165.172 7.64666 163.862 8.17702 162.858 9.25005C161.866 10.3231 161.357 11.7168 161.357 13.4435V26.912H152.977V1.33179H160.238L160.772 4.32889C161.802 3.19419 163.036 2.30616 164.485 1.68947C165.935 1.07278 167.525 0.752106 169.267 0.752106C172.535 0.752106 175.142 1.78814 177.087 3.86021V3.87254Z" fill="#1A1A1A" />
                <path d="M206.157 2.34318C208.255 3.39155 209.87 4.88393 210.989 6.79566C212.108 8.70739 212.667 10.9521 212.667 13.5052C212.667 14.3316 212.578 15.3553 212.4 16.564H193.021C193.275 17.8714 194 18.9321 195.208 19.7461C196.416 20.5601 197.967 20.9671 199.849 20.9671C201.172 20.9671 202.443 20.7821 203.69 20.3998C204.936 20.0174 206.004 19.4871 206.894 18.7964L211.268 23.3475C209.768 24.6919 208 25.7279 205.953 26.4556C203.906 27.1833 201.731 27.541 199.417 27.541C196.352 27.541 193.707 26.9983 191.457 25.9129C189.219 24.8276 187.489 23.2859 186.281 21.2755C185.073 19.2774 184.463 16.9093 184.463 14.1836C184.463 11.4578 185.06 9.2254 186.256 7.19034C187.451 5.15527 189.117 3.57655 191.24 2.45418C193.364 1.33181 195.844 0.776794 198.667 0.776794C201.49 0.776794 204.046 1.30714 206.144 2.35551L206.157 2.34318ZM194.865 8.42371C193.809 9.25007 193.224 10.4094 193.072 11.8895H204.707C204.605 10.4094 204.046 9.25007 203.028 8.42371C202.011 7.59735 200.65 7.178 198.946 7.178C197.242 7.178 195.907 7.59735 194.865 8.42371Z" fill="#1A1A1A" />
                <path d="M237.489 2.34318C239.587 3.39155 241.202 4.88393 242.321 6.79566C243.44 8.70739 244 10.9521 244 13.5052C244 14.3316 243.911 15.3553 243.733 16.564H224.353C224.608 17.8714 225.333 18.9321 226.541 19.7461C227.749 20.5601 229.3 20.9671 231.182 20.9671C232.492 20.9671 233.776 20.7821 235.022 20.3998C236.269 20.0174 237.337 19.4871 238.227 18.7964L242.601 23.3475C241.101 24.6919 239.333 25.7279 237.286 26.4556C235.239 27.1833 233.064 27.541 230.75 27.541C227.685 27.541 225.04 26.9983 222.789 25.9129C220.551 24.8276 218.822 23.2859 217.614 21.2755C216.406 19.2774 215.795 16.9093 215.795 14.1836C215.795 11.4578 216.393 9.2254 217.588 7.19034C218.784 5.15527 220.45 3.57655 222.573 2.45418C224.697 1.33181 227.176 0.776794 229.999 0.776794C232.822 0.776794 235.378 1.30714 237.477 2.35551L237.489 2.34318ZM226.197 8.42371C225.155 9.25007 224.557 10.4094 224.404 11.8895H236.04C235.938 10.4094 235.378 9.25007 234.361 8.42371C233.344 7.59735 231.983 7.178 230.279 7.178C228.575 7.178 227.24 7.59735 226.197 8.42371Z" fill="#1A1A1A" />
            </svg>

        </div>
    )
}